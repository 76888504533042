// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    width: 100%;
    height: 100vh;
    background-color: #c7e4ff;
    padding-top: 9rem;
  }

  .not-found-gif {
    margin-bottom: 2rem;
    transform: scale(1.5);
  }

  .not-found-text {
    color: #000000;
    font-size: 1.5rem;
    font-weight: 300;
  }
  
  @media screen and (max-width: 960px) {

    .not-found-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        object-fit: contain;
        width: 100%;
        height: 80vh;
        background-color: #c7e4ff;
        padding-top: 5rem;
    }

    .not-found-gif {
        margin-bottom: 1rem;
        transform: scale(1);
    }

    .not-found-text {
        color: #000000;
        font-size: 1rem;
        margin: 0rem 1rem 0rem 1rem;
    }

  }`, "",{"version":3,"sources":["webpack://./src/components/NotFoundSection.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB,qBAAqB;EACvB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;;IAEE;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;QACnB,mBAAmB;QACnB,WAAW;QACX,YAAY;QACZ,yBAAyB;QACzB,iBAAiB;IACrB;;IAEA;QACI,mBAAmB;QACnB,mBAAmB;IACvB;;IAEA;QACI,cAAc;QACd,eAAe;QACf,2BAA2B;IAC/B;;EAEF","sourcesContent":["  .not-found-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    object-fit: contain;\n    width: 100%;\n    height: 100vh;\n    background-color: #c7e4ff;\n    padding-top: 9rem;\n  }\n\n  .not-found-gif {\n    margin-bottom: 2rem;\n    transform: scale(1.5);\n  }\n\n  .not-found-text {\n    color: #000000;\n    font-size: 1.5rem;\n    font-weight: 300;\n  }\n  \n  @media screen and (max-width: 960px) {\n\n    .not-found-container {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        object-fit: contain;\n        width: 100%;\n        height: 80vh;\n        background-color: #c7e4ff;\n        padding-top: 5rem;\n    }\n\n    .not-found-gif {\n        margin-bottom: 1rem;\n        transform: scale(1);\n    }\n\n    .not-found-text {\n        color: #000000;\n        font-size: 1rem;\n        margin: 0rem 1rem 0rem 1rem;\n    }\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
