import React from 'react';
import '../../App.css'
import AboutUsSection from '../AboutUsSection'

function AboutUs() {
    return (
      <>
        <AboutUsSection />
      </>
    );
  }

  export default AboutUs;