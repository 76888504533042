import React from 'react';
import NotFoundSection from '../NotFoundSection';

function NotFound() {
  return (
    <>
        <NotFoundSection/>
    </>
  )
}

export default NotFound;