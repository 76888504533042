import React from 'react';
import '../App.css';
import './AboutUsSection.css';

function AboutUsSection() {
  return (
    <div className='about-us-container'>
      <div className='about-us-logo'>
        <h1 className='about-us-title'>About SPADATAS</h1>
        <img className='about-us-icon' src='./images/SPADATAS_logo.png' alt='The logo of SPADATAS in a shield-like shape'/>
      </div>

      <div className='about-description-container'>
        <div className='about-description-body'>
          <h2>What is SPADATAS?</h2>
          <br/>
          <p>In educational environments, cloud technology adoption in the last 10 years has enabled a data-driven and decision-making paradigm in organizations. Regarding technology, schools and universities are experiencing rapid processes of digital updating where the adoption of third-party technology solutions in the cloud results in changes in academic and learning processes. Regarding data, technology that collects and analyzes students’ data to present actionable information enhances teaching-learning processes. In the one hand, those organizations that understand the power of data can improve educational context in very different manners. In the other hand, those that do not envision this data-shift in the teaching-learning processes cannot benefit from analytical outputs. However, both perspectives have the same cloud computed technology associated problems regarding data fragility.</p>
          <br/>
          <p>Cloud storage brings the ubiquity of data to this technical transition. However, in many cases, this suggests that certain actors, beyond the control of schools and colleges, collect, handle, and treat educational data on private servers and data centers. This privatization enables the manipulation of stored records, data leaks, and unauthorized access. Hence, although the integration of cloud services in schools and universities have a positive shift, it also presents threats to all academic roles that need to be discussed in terms of protection, privacy, and confidentiality.</p>
<br/><p>Considering the context described above, in the Security and Privacy in Academic Data management at Schools (SPADATAS, Ref.: KA220-SCH-BC2B6390) project, a full awareness of data fragility comprehends the acquisition of those competencies exposed in DigiCompEdu regarding data literacy and responsible use in digital competence. Thus, the SPADATAS project aims to provide:</p>
<br/>
<ul>
    <li>understanding and awareness to primary and secondary schools about what data-driven and decision-making imply for an educational institution,</li>
    <li>which problems about data fragility are related to current educational technology and data academic management, and</li>
    <li>provide tools and frameworks to protect privacy, security, and confidentiality of student’s data.</li>
</ul>
<br/>
<p>Our approach focuses on the data fragility awareness acquisition by representatives, teachers, and students to promote data and self-data treatment good practices. For achieving our goals, the SPADATAS project will:</p>
<br/>
<ul>
    <li>Develop open and public web tools for data fragility awareness, auto-assessment and data treatment processes mapping regarding data privacy and security treatment processes in primary and secondary schools (mixed method: quantitative research with qualitative evaluation).</li>
    <li>Conduct data fragility awareness workshops. The schoolteachers will be trained as data awareness agents to raise data fragility awareness among representatives, teachers, and students from their own and other schools who voluntarily apply to the project. The schoolteachers will conduct role-targeted workshops to spread data fragility awareness and extract good practices on students’ data academic management.</li>
    <li>Develop a website and release a handbook of categorized good practices regarding data academic management to implement effective processes, or improve current, in primary and secondary schools to help protect the data privacy, security, and confidentiality of students, in collaboration with the communities and associations of a region and under the European Union’s General Data Protection Regulation.</li>
</ul>
<br/>
<p>The results expected will be twofold: on the one hand, build a digital platform for auto-assessment on data treatment regarding data privacy and security of students; on the other hand, publish resources focused on data privacy, security, and confidentiality of student’s data to help any kind of educational institution the implementation of data treatment processes to reduce privacy and security issues. Both results aim to raise awareness in data fragility.</p>        
</div>
      </div>
    </div>
  );
}

export default AboutUsSection;
